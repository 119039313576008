/**
 * dept模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const dept = {
    // 部门查询
    queryDept(data) {
        return request({
            url: '/api/dept/queryDept',
            method: 'post',
            data: data
        });
    },
    // 删除部门
    delDept(id) {
        return request({
            url: '/api/dept/delDept/'+id,
            method: 'get'
        });
    },
    // 新增部门
    addDept(data) {
        return request({
            url: '/api/dept/addDept',
            method: 'post',
            data: data
        });
    },
    // 编辑部门
    editDept(data) {
        return request({
            url: '/api/dept/editDept',
            method: 'post',
            data: data
        });
    },
}

export default dept;