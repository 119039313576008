<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="部门名称">
        <el-input v-model="queryForm.deptName" placeholder="" size="small"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="queryForm.status" placeholder="" size="small" clearable>
          <el-option v-for="s in queryForm.statusOption" :label="s.label" :value="s.value" :key="s.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-plus" @click="addInfo" size="small" v-show="hasAddPermision">新增部门</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table v-loading="loading"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              row-key="id"
              default-expand-all
              :data="tableData" style="width: 100%;">
      <el-table-column prop="deptName" label="部门名称"/>
      <!--<el-table-column prop="deptNo" label="部门编号"/>-->
      <el-table-column prop="seq" label="显示排序"/>
      <el-table-column prop="createTime" label="创建时间"/>
      <el-table-column prop="status" label="状态" >
        <template #default="scope">
          <el-tag :type="scope.row.status === 1 ? 'success' : 'info'" disable-transitions>{{ scope.row.status==1?'正常':'禁用' }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="300" align="center" v-if="hasDeletePermision||hasEditPermision||hasAddPermision">
        <template #default="scope">
          <el-button icon="el-icon-plus" size="mini" @click="addChildDept(scope.$index, scope.row)" v-show="hasAddPermision">添加子级</el-button>
          <el-button icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" v-show="hasEditPermision">编辑</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除部门['+scope.row.deptName+']吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" type="danger" v-show="hasDeletePermision">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        layout="total"
        :total="total"
    >
    </el-pagination>
  </div>

  <!--部门信息新增、修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="上级部门" prop="parentDeptName" class="label-color">
        <el-select ref="rlueFormTreeSelect" v-model="ruleForm.parentDeptName"  placeholder="请选择部门(留空则为一级部门)" fit-input-width="true" style="width:100%" clearable @clear="clearParentDept">
          <el-option style="height: auto;padding: 0;">
            <el-tree expand-on-click-node="false" default-expand-all ref="tree" highlight-current="true" :data="deptTree" node-key="id" :props="{class:customNodeClass}"
                     @node-click="handleNodeClick"/>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门名称" prop="deptName" class="label-color">
        <el-input v-model="ruleForm.deptName" placeholder="请输入部门名称" clearable ></el-input>
      </el-form-item>
      <!--<el-form-item label="部门编号" prop="deptNo" class="label-color">
        <el-input v-model="ruleForm.deptNo" placeholder="请输入部门编号" clearable ></el-input>
      </el-form-item>
      <el-form-item label="部门地址" prop="address" class="label-color">
        <el-input v-model="ruleForm.address" placeholder="请输入部门地址" clearable ></el-input>
      </el-form-item>-->
      <el-form-item label="显示排序" prop="seq" class="label-color">
        <el-input-number v-model="ruleForm.seq" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="部门状态" prop="status" class="label-color">
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="1">正常</el-radio>
          <el-radio label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveDeptInfo" v-if="isSave==true">保存</el-button>
        <el-button type="primary" @click="editDeptInfo" v-if="isSave==false">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import deptApi from '@/api/system/dept'
import {ref} from "vue";
import {handleTree} from '@/utils/treeUtil'
export default {
  data(){
    return{
      hasAddPermision:false,
      hasEditPermision:false,
      hasDeletePermision:false,
      deptTree:[],
      queryForm:{
        deptName:'',
        status:'',
        statusOption:[
          {
            label:'正常',
            value:'1'
          },
          {
            label:'禁用',
            value:'0'
          }
        ]
      },
      loading:false,
      total:0,
      pageSize:15,
      curPage:1,
      tableData: [],
      isSave:false,
      ruleForm: {
        id:'',
        parentDeptName: '',
        parentId: '',
        deptName:'',
        deptNo:'',
        address:'',
        seq: '',
        status: '',
      },
      rules: {
        deptName: [
          {
            required: true,
            message: '请输入部门名  称',
            trigger: 'blur',
          }
        ],
        seq: [
          {
            required: true,
            message: '请输入显示排序',
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择状态',
            trigger: 'blur',
          },
        ]
      },
      dialogTitle:'',
      dialogVisible:false,
      currentSelectKey:0,
    }
  },
  mounted() {
    this.$nextTick(()=> {
      // 获取设置按钮权限
      this.hasAddPermision = this.$menuUtil.hasMenu('/system/deptManage:add');
      this.hasEditPermision = this.$menuUtil.hasMenu('/system/deptManage:edit');
      this.hasDeletePermision = this.$menuUtil.hasMenu('/system/deptManage:delete');
    })
    this.queryDept();
    this.queryDeptTree();// 获取部门下拉树
  },
  methods:{
    // 清空弹窗的父级部门选择
    clearParentDept(){
      this.ruleForm.parentDeptName = '';
      this.ruleForm.parentId = null;
    },
    queryDeptTree(){
      deptApi.queryDept({
        deptName: '',
      }).then(response=>{
        this.deptTree=handleTree(response.data.data,'deptName');
      });
    },
    customNodeClass(data, node) {
      if (data.isPenultimate) {
        return 'is-penultimate'
      }
      return ''
    },
    handleNodeClick(data) {
      this.ruleForm.parentDeptName=data.label;
      this.ruleForm.parentId=data.id;
      this.$refs.rlueFormTreeSelect.blur();// 让select失去焦点，收起下拉
    },
    submit(){
      this.queryDept();
    },
    queryDept(){
      deptApi.queryDept({
        deptName: this.queryForm.deptName,
        status: this.queryForm.status,
      }).then(response=>{
        this.tableData=handleTree(response.data.data);
        console.log(response.data.data)
        this.total=response.data.data.length;
      });
    },
    handleEdit(index,row){
      this.isSave=false;
      this.dialogTitle='编辑部门信息';
      this.dialogVisible=true;
      this.ruleForm.parentDeptName=row.parentDeptName;
      this.ruleForm.parentId=row.parentId;
      this.ruleForm.deptName=row.deptName;
      this.ruleForm.deptNo=row.deptNo;
      this.ruleForm.address=row.address;
      this.ruleForm.seq=row.seq;
      this.ruleForm.id=row.id;
      this.ruleForm.status=ref(row.status.toString());
      // 选中树中值，这里要加$nextTick，否则不生效
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(row.parentId);
      });
    },
    addInfo(){
      this.isSave=true;
      this.dialogTitle='新增部门';
      this.dialogVisible=true;

      // 重置表单
      // this.$refs['ruleForm'].resetFields();
      this.ruleForm={};
      this.ruleForm.parentId='';
      this.ruleForm.seq=1;
      this.ruleForm.status=ref('1')
    },
    // 添加子级
    addChildDept(index,row){
      this.isSave = true;
      this.dialogTitle = '新增部门';
      this.dialogVisible = true;
      this.ruleForm.seq = 1;
      this.ruleForm.status = ref('1')
      // 重置表单
      this.ruleForm.parentDeptName = row.deptName;
      this.ruleForm.parentId = row.id;
    },
    handleDelete(index,row){
      deptApi.delDept(row.id).then(response=>{
        if(response.data.code==1){
          this.$notify({
            title: response.data.msg,
            type: 'success',
            offset:40,
            duration: 2000
          });
          this.queryDept();
          this.queryDeptTree();
        }else{
          this.$notify({
            title: response.data.msg,
            type: 'warning',
            offset:40,
            duration: 3000
          });
        }
      });
    },
    saveDeptInfo(){
      this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              // 调用接口保存
              deptApi.addDept(this.ruleForm).then(response=>{
                if(response.data.code==1){
                  this.$notify({
                    title: response.data.msg,
                    type: 'success',
                    offset:40,
                    duration: 2000
                  });
                  // 关闭窗口
                  this.dialogVisible=false;
                  // 重置表单
                  // this.$refs['ruleForm'].resetFields();
                  // 刷新列表
                  this.queryDept();
                  this.queryDeptTree();// 刷新下拉树
                }else{
                  this.$notify({
                    title: response.data.msg,
                    type: 'warning',
                    offset:40,
                    duration: 3000
                  });
                }
              });
            }
          }
      )
    },
    editDeptInfo(){
      this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              // 调用接口保存
              deptApi.editDept(this.ruleForm).then(response=>{
                if(response.data.code==1){
                  this.$notify({
                    title: response.data.msg,
                    type: 'success',
                    offset:40,
                    duration: 2000
                  });
                  // 关闭窗口
                  this.dialogVisible=false;
                  // 重置表单
                  // this.$refs['ruleForm'].resetFields();
                  // 刷新列表
                  this.queryDept();
                  this.queryDeptTree();// 刷新下拉树
                }else{
                  this.$notify({
                    title: '修改失败：'+response.data.msg,
                    type: 'warning',
                    offset:40,
                    duration: 3000
                  });
                  
                }
              });
            }
          }
      )
    },
    // 弹窗关闭事件
    handleClose(){
      // this.$refs['ruleForm'].resetFields()
      this.ruleForm={};
    },
  }
}
</script>

<style scoped>

</style>